let lastScrollTop = 0
let resizeTimeout
const body = document.body
const toggleMenu = document.getElementById('toggleMenu')
const mainHeader = document.querySelector('.main-header')
const mainElem = document.querySelector('main > div')
const navigationLinks = document.querySelectorAll(".page-navigation.container a")

const setMenuHeight = () => {
    const isHomepage = document.body.classList.contains('is-homepage')
    const paddingTopValue = window.innerWidth > 991 ? `${mainHeader.offsetHeight}px` : '80px'
    const targetElement = isHomepage ? document.querySelector('main .before-catalog-books > div') : mainElem

    targetElement.style.paddingTop = paddingTopValue
}

const handleScroll = () => {
    const st = window.scrollY || document.documentElement.scrollTop
    const isScrollingUp = st < lastScrollTop

    // Toggle scroll-up class based on scroll position
    mainHeader.classList.toggle('scroll-up', st > 90)

    const showBody = document.querySelector('body.show-body')

    if (st > 360 && !showBody) {
        mainHeader.classList.toggle('scroll-up', isScrollingUp)
        mainHeader.classList.toggle('scroll-down', !isScrollingUp)
    }

    lastScrollTop = Math.max(st, 0)
}

const menu = (element, add) => {
    toggleMenu.addEventListener('click', () => {
        element.classList.toggle(add)
    })
}

const initMenuControl = () => {
    menu(body, 'show-body')
    document.querySelector('body').insertAdjacentHTML('afterbegin', `<div class="hide-content"></div>`)

    navigationLinks.forEach(link => {
        link.addEventListener("click", () => {
            body.classList.remove('show-body')
        })
    })

    window.addEventListener("scroll", handleScroll, { passive: true })
    setMenuHeight()

    window.addEventListener('resize', () => {
        clearTimeout(resizeTimeout)
        resizeTimeout = setTimeout(setMenuHeight, 500)
    })
}

export { initMenuControl }
