/* global gettext */

const descriptionLength = 320

const findSpace = (text) => {
    let index = text.length - 1
    while (index >= 0 && text[index] !== ' ') {
        index--
    }
    // If no space is found, return the entire text
    return index < 0 ? text : index
}

document.addEventListener('DOMContentLoaded', () => {
    const bookDetail = document.querySelector('.book-detail')

    if (bookDetail) {
        const bookDetailDesc = bookDetail.querySelector('.description')
        const bookDetailDescFirstP = bookDetailDesc.querySelector('p:first-of-type')
        const bookDetailDescNextsP = bookDetail.querySelectorAll('.description  > *:not(:first-child)')
        const getIndexWithFirstSpace = findSpace(bookDetailDescFirstP.textContent.substring(0, descriptionLength))
        const getDescHeight = bookDetailDesc.offsetHeight

        if (bookDetailDescFirstP.textContent.length > descriptionLength || bookDetailDescNextsP) {
            bookDetailDesc.insertAdjacentHTML('afterend', `
                <section >
                    <div class="tab-content pb-3">
                        <div class="bg-body tab-pane fade-off active show" id="tab-part"
                            aria-labelledby="tab-label-part" role="tabpanel">
                            <p>
                                ${bookDetailDescFirstP.textContent.substring(0, getIndexWithFirstSpace)}...
                            </p>
                        </div>
                        <div class="bg-body tab-pane fade-off collapse-full" id="tab-full"
                        aria-labelledby="tab-label-full"
                            role="tabpanel" style="height:${getDescHeight}px">
                        </div>
                    </div>
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item d-none">
                            <a class="nav-link arrow-close active" id="tab-label-part" data-bs-toggle="tab"
                                data-bs-target="#tab-part" aria-controls="tab-part" aria-selected="true" role="tab">
                                    ${gettext('Show less')}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="tab-label-full" data-bs-toggle="tab"
                                data-bs-target="#tab-full" aria-controls="tab-full" aria-selected="false" role="tab">
                                    ${gettext('Show more')}
                            </a>
                        </li>
                    </ul>
                </section>
            `)

            const collapseFull = bookDetail.querySelector('.collapse-full')
            for (const list of bookDetailDesc.querySelectorAll('.description  > p')) {
                collapseFull.appendChild(list)
            }
        }
        const tabContent = bookDetail.querySelector('.tab-content')
        const navTabs = bookDetail.querySelector('.nav.nav-tabs')
        const getParagraphHeight = bookDetail.querySelector('.tab-content .active').offsetHeight
        tabContent.style.height = `${getParagraphHeight}px`

        navTabs.addEventListener('click', function() {
            for (const item of navTabs.querySelectorAll('li')) {
                if (item.classList.contains('d-none')) {
                    item.classList.remove('d-none')
                    tabContent.style.height = `${getParagraphHeight}px`
                } else {
                    item.classList.add('d-none')
                    tabContent.style.height = `${getDescHeight}px`
                }
            }
        })
    }
})
