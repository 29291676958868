/* global gettext */

import { initSorting } from './catalog_books_sorting'

let booksList = null
let books = null

/**
 * Control to show/hide books
 *
 */
const controlMoreBooks = (visibleBooks, countOnPage) => {
    visibleBooks.forEach((book, index) => {
        if (index >= countOnPage) {
            book.classList.add('hide-book')
        }
    })

    // If some of the filtered books are hidden
    if (booksList.querySelector('.books-tiles .book-item.hide-book')) {
        // Add btn more
        if (!booksList.querySelector('.btn-more')) {
            booksList.querySelector('.books-tiles').insertAdjacentHTML('beforeend',
                `<div class="btn-more">
                    <div class="btn btn-info">${gettext('Load more')}</div>
                </div>
            `)
        }

        // Listener for btn more
        const btnMore = booksList.querySelector('.btn-more')
        btnMore.addEventListener('click', function() {
            let hideclasscount = 0
            visibleBooks.forEach(book => {
                if (book.classList.contains('hide-book') && hideclasscount <= countOnPage - 1) {
                    book.classList.remove('hide-book')
                    hideclasscount++
                }
            })

            // If hide-book don't exist remove btn-more
            booksList.querySelector('.hide-book')
                ? null
                : btnMore.remove()
        })
    }
}

/**
 * Prepare data with books
 *
 */
const initMoreBooks = (getbooks = books) => {
    books = getbooks
    booksList = document.querySelector('.row.books-list')
    let visibleBooks = []

    books.forEach(book => {
        // Remove class before view control
        book.classList.contains('hide-book')
            ? book.classList.remove('hide-book')
            : null

        // Get all the books that pass the filtering
        !book.classList.contains('d-none')
            ? visibleBooks.push(book)
            : null
    })

    visibleBooks = initSorting(visibleBooks)

    // Check tile/list view selection
    if (booksList) {
        booksList.querySelector('.books-tiles').classList.contains('books-list-list')
            ? controlMoreBooks(visibleBooks, 6)
            : controlMoreBooks(visibleBooks, 8)
    }
}

export { initMoreBooks }
